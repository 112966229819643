/* src/pages/About.css */

.about-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-image: url('../assets/images/phone.png'); /* Backdrop image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px 0;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
}

/* Overlay to improve readability on top of background image */
.about-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.hero-section {
  background-color: rgba(255, 255, 255, 0.95); /* Increased opacity for better readability */
  color: #333;
  padding: 40px 20px;
  text-align: center;
  width: 90%;
  max-width: 800px;
  margin-top: 15vh;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.hero-section h1 {
  font-size: 2rem;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #b5651d;
}

.hero-section p {
  font-size: 1.1rem;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  color: #555;
}

.card {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 35px;
  margin: 30px auto;
  width: 90%;
  max-width: 800px;
  font-family: 'Poppins', sans-serif;
}

.card section {
  margin-bottom: 25px;
}

.card h2 {
  color: #b5651d;
  font-size: 1.8rem;
  margin-bottom: 12px;
}

.card p {
  text-align: justify;
  line-height: 1.7;
  font-size: 1rem;
  color: #444;
}

/* Button styling for "Select Your Style" button */
.select-style-button-container {
  text-align: center;
  margin-top: 20px;
}

.hero-button {
  background-color: #FFB6C1;
  color: #fff;
  border: none;
  padding: 10px 25px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.hero-button:hover {
  background-color: #8a4417;
  transform: scale(1.05);
}

/* Mobile-Friendly Adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
      font-size: 1.8rem;
  }

  .hero-section p {
      font-size: 1rem;
  }

  .card h2 {
      font-size: 1.4rem;
      color: #000;
  }

  .card p {
      font-size: 0.95rem;
  }

  .about-page {
      padding: 10px;
  }

  .hero-section, .card {
      width: 90%;
      padding: 20px;
  }

  .hero-button {
      padding: 8px 20px;
      font-size: 0.95rem;
  }
}