/* src/components/Backdrop.css */

.backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/phone.png'); /* Replace with the correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -100;
    transition: all 0.3s ease; /* Smooth animation */
  }
  
  

