/* src/pages/Phones.css */

.phones-page {
    padding: 20px;
    text-align: center;
    margin-top: 15vh;
  }
  
  .phones-page h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .product-title {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0;
  }
  
  .product-price {
    font-size: 1rem;
    color: #888;
    margin-bottom: 15px;
  }
  
  .product-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .product-link:hover {
    background-color: #0056b3;
  }
  
  /* Mobile-Friendly Adjustments */
  @media (max-width: 768px) {
    .product-card {
      padding: 15px;
    }
  
    .product-title {
      font-size: 1.1rem;
    }
  
    .product-price {
      font-size: 0.95rem;
    }
  
    .product-link {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .phones-page h1 {
      font-size: 1.8rem;
    }
  
    .product-title {
      font-size: 1rem;
    }
  
    .product-price {
      font-size: 0.9rem;
    }
  
    .product-link {
      padding: 7px 14px;
    }
  }