/* src/pages/Contact.css */

.contact-page {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 15vh;
  }
  
  .contact-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 700px;
    width: 100%;
    text-align: center;
  }
  
  .contact-card h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-card p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .form-group label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
    text-align: left;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f9fa;
  }
  
  button {
    padding: 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .contact-email {
    margin-top: 30px;
  }
  
  .contact-email h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-email p {
    font-size: 1rem;
    color: #007bff;
  }
  
  .contact-email a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-email a:hover {
    text-decoration: underline;
  }
  
  /* Mobile adjustments */
  @media (max-width: 600px) {
    .contact-card h1 {
      font-size: 2rem;
    }
  
    .contact-card p, .contact-email h3 {
      font-size: 1rem;
    }
  
    .form-group label, .form-group input, .form-group textarea {
      font-size: 0.9rem;
    }
  }