/* src/pages/Help.css */

.help-page {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 15vh;
  }
  
  /* Manual Section */
  .help-section {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .help-section h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  

  
  /* Video Thumbnail Styling */
  .video-thumbnail {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .thumbnail-image {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .thumbnail-image:hover {
    transform: scale(1.05);
  }
  
  /* FAQ Section */
  .faq-section {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    
  }
  
  .faq-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 5vh;
    justify-content: center;
    display:flex;
  }
  
  .faq-item {
    margin-bottom: 20px;
    
  }
  
  .faq-item h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 2vh;
    
    display:flex;
  }
  
  .faq-item p {
    font-size: 1rem;
    color: #555;
    display:flex;
    margin-bottom: 5vh;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .help-section h1,
    .faq-section h2 {
      font-size: 1.8rem;
    }
  
    .faq-item h3 {
      font-size: 1rem;
    }
  
    .faq-item p,
    .manual-video-link a {
      font-size: 0.9rem;
    }
  }