.construction-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFB6C1; /* Pink theme color */
    color: white;
    font-family: 'Poppins', sans-serif;
    padding: 5px 0;
    font-size: 16px;
    width: 100%;
    top: 0;
    z-index: 1001; /* Ensure the banner stays above other elements */
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.construction-icon {
    width: 20px; /* Adjust size of the construction icon */
    height: 20px;
    margin-right: 10px;
}

.construction-banner span {
    margin-right: 8px;
    font-size: 18px; /* Adjust emoji size */
    display: flex; /* Ensure proper centering for the icon */
    align-items: center;
}

.construction-banner p {
    margin: 0; /* Remove default margins */
}

@media (max-width: 768px) {
    .construction-banner p{
        font-size: 12px;
    }
    .construction-icon span {
        width: 15px; /* Adjust size of the construction icon */
        height: 15px;
        margin-right: 8px;
    }
    
}
