/* AbsoluteNeed Section Styling */
.absolute-need-section {
    width: 100%;
    padding: 50px;
    background-color: #f9f9f9; /* Off-white background */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0;
    background-size: cover;
    overflow-x: hidden; /* Prevent horizontal overflow */
    box-sizing: border-box; /* Ensure padding and borders are included in width calculations */
}

/* Content layout (image left, text right) */
.absolute-need-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    gap: 40px;
    box-sizing: border-box;
    padding: 0 15px; /* Add padding to prevent content from touching edges */
}

/* Image container */
.image-container {
    flex-basis: 40%; /* Ensure the image takes up 40% of the container */
    box-sizing: border-box;
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Round the corners */
    object-fit: cover; /* Keep the aspect ratio */
}

/* Text container */
.text-container {
    flex-basis: 55%; /* Ensure the text takes up 55% of the container */
    box-sizing: border-box;
}

.card-title {
    font-size: 40px;
    color: #333;
    font-family: 'Dancing Script', cursive;
    margin-bottom: 20px;
}

/* Paragraph Styling */
.text-container p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
}

/* Button Styling */
.absolute-need-button {
    background-color: #ff6b6b;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.absolute-need-button:hover {
    background-color: #ff4c4c;
}

.absolute-need-section.drawer-open {
    transform: translateY(150px); /* Adjust the value for smooth push down */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .absolute-need-section {
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .absolute-need-content {
        flex-direction: column;
        text-align: center;
    }

    .image-container {
        margin-bottom: 20px;
    }

    .product-image {
        width: 100%;
        height: auto; /* Ensure the image scales properly */
    }

    .card-title {
        font-size: 30px;
    }

    .text-container p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .product-image {
        width: 100%;
    }

    .card-title {
        font-size: 24px;
    }

    .text-container p {
        font-size: 14px;
    }
}


